import { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../providers/LanguageProvider";

function SliderComponent({ sliderValue, setSliderValue }) {

    const sliderRef = useRef();
    const { t } = useTranslation();

    const spanRef = useRef();
  const snapPoints = [0, 1, 2, 3];
  const settings = {
    fill: "var(--light-theme-bg1)",
    background: "#ECECEC",
  };
useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener("input", (event) => {
        const closest = findClosestSnapPoint(event.target.value, snapPoints);
        event.target.value = closest;
        setSliderValue(closest);
        spanRef.current.innerHTML = closest;
        applyFill(event.target);
      });
      applyFill(slider);
    }
  }, []);

  const findClosestSnapPoint = (value, snapPoints) => {
    return snapPoints.reduce((prev, curr) =>
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    );
  };

  const applyFill = (slider) => {
    const percentage =
      (100 * (slider.value - slider.min)) / (slider.max - slider.min);
    const bg = `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${
      settings.background
    } ${percentage + 0.1}%)`;
    slider.style.background = bg;
  };
  
  return (
    <div className="">
                <div className="water-slider text-slider w-100">
                  <div className="range-slider">
                    <span ref={spanRef} className="range-slider__value" />
                    <input
                      ref={sliderRef}
                      id="range-slider-val"
                      className="range-slider__range browser-default"
                      type="range"
                      value={sliderValue}
                      min={0}
                      max={3}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-1 mt-2">
                  <span
                    className="pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title=""
                    data-bs-original-title="Approximately 600 - 1200 words"
                  >
                    {t("Short")}
                  </span>
                  <span
                    className="pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title=""
                    data-bs-original-title="Approximately  1800 - 3000 words"
                  >
                    {t("Medium")}
                  </span>
                  <span
                    className="pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title=""
                    data-bs-original-title="Approximately 4200 - 7200 words"
                  >
                    {t("Long")}
                  </span>
                  <span
                    className="pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title=""
                    data-bs-original-title="Approximately 9000 - 15000 words"
                  >
                    {t("Very Long")}
                  </span>
                </div>
              </div>
  );
}

export default SliderComponent;