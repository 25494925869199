// Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app";
import { getAuth } from  "@firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLGDXt_9OA827qaWSX1NABgjJiZWI8rdE",
  authDomain: "transk-d9bcc.firebaseapp.com",
  projectId: "eskritor-1dbe5",
  storageBucket: "eskritor-1dbe5.appspot.com",
  messagingSenderId: "844747373196",
  appId: "1:844747373196:web:c51275d517fab5644e19a9",
  measurementId: "G-V36LGE3X4B"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
