import { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../providers/LanguageProvider";

function Templates({ setLeftCardType, categories, selectedTab, onClickTemplate}) {
  const scrollingTabRef = useRef(null);
  const [activeTab, setActiveTab] = useState(selectedTab ? selectedTab : 'all');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCategories, setFilteredCategories] = useState(categories);
  const { t } = useTranslation();

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setActiveTab('all');
  
    const newFilteredCategories = categories.map(category => {
      // Filter templates within each category to find matches.
      const filteredTemplates = category.templates.filter(template =>
        template.title.toLowerCase().includes(query)
      );
  
      // Return the category with the filtered templates, even if empty.
      return { ...category, templates: filteredTemplates };
    });
  
    setFilteredCategories(newFilteredCategories);
  };

  useEffect(() => {
    setFilteredCategories(categories);
  }, [categories]);


  useEffect(() => {
    const scrollingTab = scrollingTabRef.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    const onMouseDown = (e) => {
      isDown = true;
      scrollingTab.classList.add('active');
      startX = e.pageX - scrollingTab.offsetLeft;
      scrollLeft = scrollingTab.scrollLeft;
    };

    const onMouseLeave = () => {
      isDown = false;
      scrollingTab.classList.remove('active');
    };

    const onMouseUp = () => {
      isDown = false;
      scrollingTab.classList.remove('active');
    };

    const onMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - scrollingTab.offsetLeft;
      const walk = (x - startX) * 3; // scroll-fast
      scrollingTab.scrollLeft = scrollLeft - walk;
    };

    scrollingTab.addEventListener('mousedown', onMouseDown);
    scrollingTab.addEventListener('mouseleave', onMouseLeave);
    scrollingTab.addEventListener('mouseup', onMouseUp);
    scrollingTab.addEventListener('mousemove', onMouseMove);

    return () => {
      scrollingTab.removeEventListener('mousedown', onMouseDown);
      scrollingTab.removeEventListener('mouseleave', onMouseLeave);
      scrollingTab.removeEventListener('mouseup', onMouseUp);
      scrollingTab.removeEventListener('mousemove', onMouseMove);
    };
  }, []);
  return (
    <div className="first-close" id="text-editor-template"
    style={{
      display: "block",
      textAlign: "start",
    }}>
      <h3 className="ft-18-title medium">
        <a
          onClick={() => setLeftCardType("")}
          href="#" class="theme-hover-link" id="text-editor-template-close"
        >
          <span className="me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={12}
              height={12}
              viewBox="0 0 5 9"
              fill="none"
            >
              <path
                d="M4 8L1 4.5L4 1"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>{" "}
          {t("Templates")}
        </a>
      </h3>
      <div className="form-group input-icon-div mt-4">
        <input
          type="text"
          name=""
          className="form-control border-input"
          placeholder={t("Search for templates")}
          onChange={handleSearchChange}
          value={searchQuery}
        />
        <span className="input-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={17}
            height={17}
            viewBox="0 0 17 17"
            fill="none"
          >
            <g clipPath="url(#clip0_215_2673)">
              <path
                d="M16.21 14.7531C16.2377 14.776 16.2599 14.8048 16.2752 14.8372C16.2904 14.8697 16.2983 14.9052 16.2983 14.941C16.2983 14.9769 16.2904 15.0124 16.2752 15.0449C16.2599 15.0773 16.2377 15.1061 16.21 15.129L15.1274 16.2116C15.0784 16.2545 15.0155 16.2781 14.9504 16.2781C14.8854 16.2781 14.8225 16.2545 14.7735 16.2116L11.4596 12.8977C10.721 13.4587 9.88072 13.8714 8.9852 14.113C8.02639 14.3654 7.02479 14.4106 6.04713 14.2456C5.281 14.1325 4.54067 13.8858 3.85984 13.5168C3.18934 13.153 2.57235 12.6983 2.02639 12.1655C1.47719 11.6299 1.0347 10.9948 0.722462 10.2941C0.403282 9.58939 0.180575 8.84485 0.0603621 8.08062C-0.092523 6.96378 0.0283335 5.82651 0.412525 4.76675C0.771626 3.75894 1.35327 2.84505 2.11422 2.09301C2.87584 1.34927 3.78738 0.77661 4.78796 0.413266C5.84745 0.0278754 6.98502 -0.0930125 8.10184 0.0611028C9.63373 0.260899 11.0599 0.950872 12.1673 2.02798C12.7128 2.55918 13.1746 3.16993 13.5371 3.83947C13.9077 4.5272 14.1533 5.2752 14.2626 6.04872C14.427 7.01175 14.3817 7.99891 14.13 8.94288C13.8828 9.85 13.4708 10.7039 12.9147 11.4621L16.21 14.7531ZM7.17372 12.304C7.8566 12.3087 8.53319 12.1733 9.16171 11.9063C10.3934 11.3791 11.3738 10.3963 11.8979 9.16329C12.1604 8.53321 12.2955 7.85742 12.2955 7.17488C12.2955 6.49234 12.1604 5.81655 11.8979 5.18647C11.3723 3.95409 10.3907 2.97252 9.15833 2.44686C8.52986 2.17966 7.85324 2.04428 7.17034 2.04909C6.48744 2.04428 5.81082 2.17966 5.18235 2.44686C4.57631 2.70367 4.02784 3.07926 3.56932 3.55149C3.10984 4.0282 2.73715 4.58151 2.46808 5.18647C2.1877 5.8108 2.05173 6.49032 2.07031 7.17446C2.07937 7.85596 2.21422 8.52993 2.46808 9.16245C2.71715 9.78008 3.09353 10.3383 3.5727 10.8008C4.04199 11.2583 4.58795 11.6299 5.18573 11.8987C5.81251 12.1726 6.49061 12.3094 7.17456 12.2998L7.17372 12.304Z"
                fill="#AEACA8"
              />
            </g>
            <defs>
              <clipPath id="clip0_215_2673">
                <rect width="16.3" height="16.2789" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      </div>
      <ul
        className="nav nav-pills tag-pills rounded-btn-pills scrolling-tab mb-4"
        id="pills-tab"
        role="tablist"
        ref={scrollingTabRef}
      >
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'all' ? 'active' : ''}`}
            onClick={() => setActiveTab('all')}
          >
            All
          </button>
        </li>
        {filteredCategories.map((template, index) => (
          <li className="nav-item" role="presentation" key={index}>
            <button
              className={`nav-link ${activeTab === template.category_name ? 'active' : ''}`}
              onClick={() => setActiveTab(template.category_name)}
        
            >
              {template.category_name}
            </button>
          </li>
        ))}
      </ul>
      <div
        className="tab-content template-tab-content-area-height"
        id="pills-tabContent"
      >
        <div
          className= {`tab-pane fade ${activeTab === 'all' ? 'show active' : ''}`}
        >
          {filteredCategories.map((category, index) => (
            <>
            {searchQuery === "" &&
              <p className="ft-18-title light-color mb-2">
                {category.category_name}
              </p>}
              <div className="editor-template-list"
              style={searchQuery === "" ? {} : {margin:'0px'}}
              >
                {category.templates.map((template, index) => (
                  <div className="editor-template-list-flex" key={index}
                  onClick={() => onClickTemplate(template)}
                  >
                    <span className="d-flex align-items-center">
                      <span className="grey-dot-round" /> {template.title}
                    </span>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={10}
                        height={10}
                        viewBox="0 0 5 9"
                        fill="none"
                      >
                        <path
                          d="M1 1L4 4.5L1 8"
                          stroke="#4E4C4A"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>
        {filteredCategories.map((category, index) => (
          <div
            className = {`tab-pane fade ${activeTab === category.category_name ? 'show active' : ''}`}
            key={index}
          >
            <p className="ft-18-title light-color mb-2">
              {category.category_name}
            </p>
            <div className="editor-template-list">
              {category.templates.map((template, index) => (
                <div className="editor-template-list-flex" key={index}
                onClick={() => onClickTemplate(template)}>
                  <span className="d-flex align-items-center">
                    <span className="grey-dot-round" /> {template.title}
                  </span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={10}
                      height={10}
                      viewBox="0 0 5 9"
                      fill="none"
                    >
                      <path
                        d="M1 1L4 4.5L1 8"
                        stroke="#4E4C4A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Templates;
