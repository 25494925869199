import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "./config";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  signInWithCustomToken,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
} from "@firebase/auth";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Gleap from "gleap";
import { fetchData } from "../hooks/apiUtils";

import { sendAmplitudeData } from "./amplitude";
import { showToast } from "../components/toast";
import { useTranslation } from "../providers/LanguageProvider";
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null); // [name, email, uid
  const [hashedId, setHashedId] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { t } = useTranslation();

  const auth = getAuth();
  auth.useDeviceLanguage();
  const navigate = useNavigate();
  const path = useLocation().pathname;

  useEffect(() => {
    getStatus();
  }, [location, hashedId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);

  const getStatus = async () => {
    if (hashedId) {
      const response = await fetchData(
        "https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" +
          hashedId
      );
      setUserData(response);
    }
  };
  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  async function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function sendResetPasswordMail(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function sendVerification(user) {
    return sendEmailVerification(user);
  }

  function signInWithToken(token) {
    return signInWithCustomToken(auth, token);
  }

  function googleLogin() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }
  function updateProfileName(name) {
    return updateProfile(auth.currentUser, { displayName: name });
  }
  const automateLogin = async (uid) => {
    const response = await fetch(
      "https://hg0761tbmf.execute-api.eu-central-1.amazonaws.com/default/automateLogin?hashedId=" +
        uid
    );

    if (response.status === 200) {
      const data = await response.json();
      console.log(data);
      console.log(data["customToken"]); // Here you should see your token or any other data sent from Lambda
      const token = data["customToken"];
      await signInWithToken(token);
      console.log("keke");
    } else {
      console.log("Error:", response.status, response.statusText);
    }

    console.log("kek");
  };
  function removeQueryParameters() {
    // Navigate without reloading the page
    navigate(path, { replace: true });
  }

  const findLanguage = async () => {
    try {
      const browserLanguage =
        navigator.language || navigator.userLanguage || "en-US";
      const [iso639_1, countryCode] = browserLanguage.split("-");

      localStorage.setItem("dashboard-language", iso639_1);
      localStorage.setItem("dashboard-country", countryCode || "US");
      localStorage.setItem("transcript-language", browserLanguage);
      window.location.reload();
    } catch (error) {
      console.error("Error detecting browser language:", error);
    }
  };
  const directLogin = async (token) => {
    return signInWithToken(token);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const uid = queryParams.get("uid");
    const token = queryParams.get("t");
      if (token) {
        logout();
        directLogin(token);
      }
      if (uid) {
        logout();
        automateLogin(uid);
      }
  } ,[]);

  useEffect(() => {

   
    // This is a listener which Firebase provides to determine
    // authentication state. It's automatically managed, so if the token
    // is invalidated or expired, the user will be logged out automatically.
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
      console.log(user);
      

      if (user) {
        if (user.emailVerified) {
          const queryParams = new URLSearchParams(location.search);
          // To retrieve a specific query parameter:
          const option = queryParams.get("option");
          const lang = queryParams.get("lang");

          if (lang) {
            localStorage.setItem("dashboard-language", lang);
          }

          if (option) {
            if (option === "transkriptor") {
              sessionStorage.setItem("transkriptor-redirect", true);
              removeQueryParameters();
            }
          }
       
          console.log("user is logged in");
          fetch(
            "https://v3t1p3ucic.execute-api.eu-central-1.amazonaws.com/default/AA-API-FireGetHashedID?uid=" +
              user.uid +
              "&app=Eskritor"
          )
            .then((response) => response.json())
            .then(async (data) => {
              console.log(data);
              const temp_hashedId = data.hashedId;

              // for prod
              console.log("LOGGED IN HERE");
              setHashedId(data.hashedId);
              fetch(
                "https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" +
                  data.hashedId
              )
                .then((response) => response.json())
                .then((data) => {
                  setUserData(data);
                  let segment = data.UserSegment;

                  const queryParams = new URLSearchParams(location.search);
                  // Get the user agent string
                  var userAgentString = navigator.userAgent;

                  // Detect the browser name
                  var browserName = "";
                  if (userAgentString.indexOf("Chrome") != -1) {
                    browserName = "Chrome";
                  } else if (userAgentString.indexOf("Firefox") != -1) {
                    browserName = "Firefox";
                  } else if (userAgentString.indexOf("Safari") != -1) {
                    browserName = "Safari";
                  } else if (
                    userAgentString.indexOf("Opera") != -1 ||
                    userAgentString.indexOf("OPR") != -1
                  ) {
                    browserName = "Opera";
                  } else if (userAgentString.indexOf("Edge") != -1) {
                    browserName = "Edge";
                  } else if (
                    userAgentString.indexOf("MSIE") != -1 ||
                    !!document.documentMode == true
                  ) {
                    browserName = "Internet Explorer";
                  } else {
                    browserName = "Unknown browser";
                  }
                  Gleap.identify(
                    "User#" + temp_hashedId,
                    {
                      email: data.Mail,
                      customData: {
                        subscription: data.SStatus,
                        segment: data.UserSegment,
                        language: localStorage.getItem("dashboard-language"),
                        browser: browserName,
                      },
                    },
                    temp_hashedId
                  );
                  // To retrieve a specific query parameter:
                });
            });
        } else {
          sendVerification(user);
          showToast(t("Please check your mailbox to verify your email."), {
            type: "success",
            position: "top-center",
          });

          logout();

          if (
            path !== "/signIn" &&
            path !== "/register" &&
            !path.startsWith("/authentication")
          ) {
            navigate("/signIn");
            console.log(
              "user is not logged in and path is not /signIn or /register"
            );
          }
        }
      } else {
        console.log("user is not logged in");
        // To retrieve a specific query parameter:
       
      }
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    hashedId,
    userData,
    signup,
    getStatus,
    login,
    logout,
    sendResetPasswordMail,
    sendVerification,
    signInWithToken,
    googleLogin,
    updateProfileName,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
