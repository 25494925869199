import React from "react";
import { Quill } from "react-quill";
import "./quill.css";
import { useTranslation } from "../../providers/LanguageProvider";
import EditorDropdown from "./EditorDropdown";
import LanguageDropdown from "./LanguageDropdown";
// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not

var icons = Quill.import("ui/icons");
icons["header"][
  "1"
] = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-type-h1" viewBox="0 0 16 16">
<path d="M7.648 13V3H6.3v4.234H1.348V3H0v10h1.348V8.421H6.3V13zM14 13V3h-1.333l-2.381 1.766V6.12L12.6 4.443h.066V13z"/>
</svg>`;

icons["header"][
  "2"
] = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-type-h2" viewBox="0 0 16 16">
<path d="M7.495 13V3.201H6.174v4.15H1.32V3.2H0V13h1.32V8.513h4.854V13zm3.174-7.071v-.05c0-.934.66-1.752 1.801-1.752 1.005 0 1.76.639 1.76 1.651 0 .898-.582 1.58-1.12 2.19l-3.69 4.2V13h6.331v-1.149h-4.458v-.079L13.9 8.786c.919-1.048 1.666-1.874 1.666-3.101C15.565 4.149 14.35 3 12.499 3 10.46 3 9.384 4.393 9.384 5.879v.05z"/>
</svg>`;

icons["header"][
  "3"
] = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-type-h3" viewBox="0 0 16 16">
<path d="M11.07 8.4h1.049c1.174 0 1.99.69 2.004 1.724s-.802 1.786-2.068 1.779c-1.11-.007-1.905-.605-1.99-1.357h-1.21C8.926 11.91 10.116 13 12.028 13c1.99 0 3.439-1.188 3.404-2.87-.028-1.553-1.287-2.221-2.096-2.313v-.07c.724-.127 1.814-.935 1.772-2.293-.035-1.392-1.21-2.468-3.038-2.454-1.927.007-2.94 1.196-2.981 2.426h1.23c.064-.71.732-1.336 1.744-1.336 1.027 0 1.744.64 1.744 1.568.007.95-.738 1.639-1.744 1.639h-.991V8.4ZM7.495 13V3.201H6.174v4.15H1.32V3.2H0V13h1.32V8.513h4.854V13z"/>
</svg>`;

icons[
  "undo"
] = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
<path d="M10.4242 6.21276L7.23392 8.93507C5.97381 10.0107 5.34375 10.548 5.34375 11.2401C5.34375 11.9321 5.97381 12.4704 7.23392 13.5451L10.4242 16.2646C10.9996 16.755 11.2868 17.0007 11.5242 16.8985C11.7615 16.7963 11.7615 16.4296 11.7615 15.6888V13.8836C14.6529 13.8836 17.7847 15.2049 18.9901 17.4077C18.9901 10.3586 14.7067 8.59279 11.7615 8.59279V6.78948C11.7615 6.05053 11.7615 5.68199 11.5242 5.57978C11.2868 5.47756 10.9996 5.71857 10.4242 6.21276Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

icons[
  "redo"
] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M13.9963 6.21276L17.0589 8.93226C18.2686 10.0069 18.8734 10.5452 18.8734 11.2373C18.8734 11.9293 18.2686 12.4704 17.0589 13.5451L13.9963 16.2646C13.444 16.755 13.1683 17.0007 12.9404 16.8985C12.7126 16.7963 12.7126 16.4296 12.7126 15.6888V13.8836C9.93691 13.8836 6.93058 15.2049 5.77344 17.4077C5.77344 10.3586 9.88534 8.59279 12.7126 8.59279V6.78948C12.7126 6.05053 12.7126 5.68199 12.9404 5.57978C13.1683 5.47756 13.4449 5.71857 13.9963 6.21276Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

icons[
  "bold"
] = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M6.3125 5.87538C6.3125 5.50232 6.4607 5.14454 6.72449 4.88074C6.98829 4.61695 7.34607 4.46875 7.71913 4.46875H10.0041C10.3673 4.46875 10.7269 4.54028 11.0625 4.67927C11.398 4.81825 11.7029 5.02197 11.9597 5.27878C12.2165 5.53559 12.4202 5.84046 12.5592 6.176C12.6982 6.51154 12.7697 6.87117 12.7697 7.23435C12.7697 7.59753 12.6982 7.95716 12.5592 8.2927C12.4202 8.62824 12.2165 8.93311 11.9597 9.18992C11.7029 9.44673 11.398 9.65045 11.0625 9.78943C10.7269 9.92842 10.3673 9.99995 10.0041 9.99995H6.3125V5.87538Z" stroke="currentColor" stroke-width="3"/>
<path d="M6.3125 10H10.9231C11.2863 10 11.6459 10.0715 11.9815 10.2105C12.317 10.3495 12.6219 10.5532 12.8787 10.81C13.1355 11.0668 13.3392 11.3717 13.4782 11.7073C13.6172 12.0428 13.6887 12.4024 13.6887 12.7656C13.6887 13.1288 13.6172 13.4884 13.4782 13.8239C13.3392 14.1595 13.1355 14.4644 12.8787 14.7212C12.6219 14.978 12.317 15.1817 11.9815 15.3207C11.6459 15.4597 11.2863 15.5312 10.9231 15.5312H7.39717C7.1095 15.5312 6.83361 15.4169 6.63019 15.2135C6.42678 15.0101 6.3125 14.7342 6.3125 14.4465V10Z" stroke="currentColor" stroke-width="3"/>
</svg>`;

icons[
  "italic"
] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M7.57422 17.5343H13.4736M10.5244 6.46875H16.4238M10.5244 17.5343L13.4736 6.46875" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

icons[
  "underline"
] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M7.57422 17.5312H16.4238" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.57422 6.46875V9.78747C7.59114 10.9498 8.06477 12.0589 8.89274 12.8749C9.72071 13.6909 10.8365 14.1483 11.999 14.1483C13.1615 14.1483 14.2773 13.6909 15.1053 12.8749C15.9333 12.0589 16.4069 10.9498 16.4238 9.78747V6.46875" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];

// Quill Toolbar component
export const QuillToolbar = ({
  charCount,
  wordCount,
  fileName,
  contentLanguage,
  setContentLanguage,
  hashedId,
  eskritorExportFile,
  pkRef,
  skRef,
  onSaveFile,
  onDuplicateFile,
}) => {
  const { t } = useTranslation();
  const truncate = (str, n) => {
    if (!str) return t("Text Editor");
    if (str.length > n) return str.substr(0, n - 1) + "...";
    else return str;
  };

  return (
    <div id="toolbar">
      <EditorDropdown
        hashedId={hashedId}
        pkRef={pkRef}
        skRef={skRef}
        eskritorExportFile={eskritorExportFile}
        onSaveFile={onSaveFile}
        onDuplicateFile={onDuplicateFile}
      />
      <div class="w-100">
        <div className="text-format-icons d-flex">
          <p className="medium dark-color mb-0">{truncate(fileName, 70)}</p>
          <LanguageDropdown
            contentLanguage={contentLanguage}
            setContentLanguage={setContentLanguage}
          />
        </div>
        <div class="text-format-icons">
          <div class="text-format-flex-div">
            <div class="text-format-flex">
              <button
                className="ql-undo"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={t("Undo")}
              >
                <CustomUndo />
              </button>
              <button
                className="ql-redo"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={t("Redo")}
              >
                <CustomRedo />
              </button>
            </div>

            <div class="text-format-flex">
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={t("Bold")}
                className="ql-bold "
              />
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={t("Italic")}
                className="ql-italic"
              />
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={t("Underline")}
                className="ql-underline"
              />

              <button
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={t("Strike Through")}
                className="ql-strike"
              />
            </div>
            <div class="text-format-flex">
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={t("Header") + " 1"}
                className="ql-header"
                value="1"
              >
                H1
              </button>
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={t("Header") + " 2"}
                className="ql-header"
                value="2"
              >
                H2
              </button>
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={t("Header") + " 3"}
                className="ql-header"
                value="3"
              >
                H3
              </button>
            </div>
            <div class="text-format-flex">
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={t("Ordered List")}
                className="ql-list"
                value="ordered"
              />
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={t("Unordered List")}
                className="ql-list"
                value="bullet"
              />
            </div>
            <select className="ql-align" />

          </div>
          <div class="text-format-flex-text">
            <span class="light-color">
              <span class="dark-color">{t("Words")}</span> &nbsp; {wordCount}
            </span>
            <span class="light-color ms-2">
              <span class="dark-color">{t("Characters")}</span> &nbsp;{" "}
              {charCount}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuillToolbar;
