import { useEffect, useState } from "react";
import { useTranslation } from "../../providers/LanguageProvider";

const LanguageDropdown = ({ contentLanguage, setContentLanguage }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();



 
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectLanguage = (language) => {
    setContentLanguage(language);
    setIsOpen(false);
  };

  const filteredLanguages = Object.keys(languages)
    .filter((key) =>
      languages[key].toLowerCase().includes(searchTerm.toLowerCase())
    )
    .reduce((obj, key) => {
      obj[key] = languages[key];
      return obj;
    }, {});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".dropdown") === null) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown">
      <div
        className="btn dropdown-toggle regular py-0"
        role="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded={isOpen}
        onClick={handleToggleDropdown}
        style={{ 
          width: "140px", textAlign: "right"
       }}
      >
        {languages[contentLanguage]}
      </div>
      {isOpen && (
        <ul
          className="dropdown-menu dropdown-menu-end language-dropdown-menu show"
          aria-labelledby="dropdownMenuButton1"
          style={
            {
              maxHeight: "300px",
              overflowY: "auto",
              left: "-40px",
              zIndex: "1000",
              overflowX: "visible",
            }
          }
        >
          <li>
            <div className="dropdown-item">
              <input
                type="text"
                className="form-control border-input"
                placeholder={t("Search")}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </li>
          {Object.entries(filteredLanguages).map(([key, name]) => (
            <li key={key}>
              <div
                onClick={() => handleSelectLanguage(key)}
                className="dropdown-item"
              >
                {name}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const languages = {
  af: "Afrikaans (Afrikaans)",
  am: "Amharic (አማርኛ)",
  ar: "Arabic (عربي)",
  bn: "Bengali (বুটে)",
  bg: "Bulgarian (Български)",
  my: "Burmese (မြန်မာနိုင်ငံ)",
  ca: "Catalan (Calalana)",
  zh: "Chinese (中国人)",
  hr: "Croatian (Hrvatski)",
  cs: "Czech (Čeština)",
  da: "Danish (Dansk)",
  nl: "Dutch (Nederlands)",
  en: "English (English)",
  et: "Estonian (Eesti)",
  fil: "Filipino (Pilipino)",
  fi: "Finnish (Suomalainen)",
  fr: "French (Français)",
  de: "German (Deutsch)",
  el: "Greek (Ελληνικά)",
  gu: "Gujarati (ગુજરાત)",
  he: "Hebrew (עִברִית)",
  hi: "Hindi (हिन्दी)",
  hu: "Hungarian (Magyar)",
  is: "Icelandic (Íslenskt)",
  id: "Indonesian ",
  ga: "Irish (Gaeilge)",
  it: "Italian (Italiano)",
  ja: "Japanese (日本)",
  jv: "Javanese (Basa Jawa)",
  kn: "Kannada (ಕನ್ನಡ)",
  km: "Khmer (ខ្មែរ)",
  ko: "Korean (한국인)",
  lo: "Lao (ພາສາລາວ)",
  lv: "Latvian (Latviski)",
  lt: "Lithuanian (Lietuvių)",
  mk: "Macedonian ",
  ms: "Malay (Melayu)",
  mt: "Maltese (Maltaca)",
  mr: "Marathi (मराठी)",
  nb: "Norwegian (Norsk)",
  fa: "Persian (فارسی)",
  pl: "Polish (Dialekt)",
  pt: "Portuguese (Português)",
  ro: "Romanian (Română)",
  ru: "Russian (Русский)",
  sr: "Serbian (Српски)",
  si: "Sinhala (සිංහල)",
  sk: "Slovak (Slovenský)",
  sl: "Slovenian (Slovenščina)",
  es: "Spanish (Español)",
  sw: "Swahili (Svahili)",
  sv: "Swedish (Svenska)",
  ta: "Tamil (தமிழ்)",
  te: "Telugu (తెలుగు)",
  th: "Thai (ไทย)",
  tr: "Turkish (Türkçe)",
  uk: "Ukrainian ",
  ur: "Urdu (اردو)",
  uz: "Uzbek (O'Zbek Tili)",
  vi: "Vietnamese ",
  zu: "Zulu (Isizulu)",
};

export default LanguageDropdown;
