import { sendAmplitudeData } from "../Auth/amplitude";
export const subscription_aware = async(userData, currentUser, type, message) => {   
    const url = "/payment";
    const current_url = window.location.href;
    const website = "eskritor"
    var cta_event = "subscription_evaluate"
    var content = message || "Upgrade to get more minutes and transcribe more files."
    var reason = type || "insufficient_credit"
    var cta_url = `app.${website}.com${url}`
  
     if(type === "header_upgrade_button" || type === "sidebar_upgrade_button" || type === "upgrade_box"){
        reason = "check_premium";
        content = "Upgrade"
    console.log("Subscription Aware", userData, currentUser, type)
    } 
    if(type === "sidebar_upgrade_button" && userData.SStatus === "Active"){
        cta_url = "checkout.stripe.com"
        cta_event = "subscription_checkout"
    }
    const payload = {
        data_source: "frontend",
        subscription_platform: "web",
        subscription_cta_url: cta_url,
        subscription_cta_event: 'subscription_evaluate',
        subscription_language: localStorage.getItem("dashboard-language"),
        subscription_cta_content: content !== "Upgrade" ? `${reason}_modal` : type, 
        subscription_feature: content !== "Upgrade" ? `${type}` : "none",
        subscription_content: content,
        subscription_reason: reason,
        subscription_product: website,
        subscription_feature_status: "production"
    }
    if(type === "trial_file_limit"){
        payload.subscription_feature = "local_transcription"
    }
    sendAmplitudeData("subscription_aware", userData, currentUser, payload.subscription_cta_content, current_url, payload.subscription_cta_url, payload)
    
}
