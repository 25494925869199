import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDataPost } from '../../hooks/apiUtils';
import { useTranslation } from '../../providers/LanguageProvider';
import { showToast } from '../../components/toast';

const EditorDropdown = ({hashedId, pkRef, skRef, eskritorExportFile, onSaveFile, onDuplicateFile}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (event.target.closest(".multilayer-dropdown")) return;
        setIsDropdownOpen(false);
        setIsSubmenuOpen(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleSubmenu = (event) => {
    event.preventDefault(); 
    event.stopPropagation(); 
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const handleDelete = () => {
    fetchDataPost("https://2dwvlsoy9a.execute-api.eu-central-1.amazonaws.com/default/AA-API-DeleteFile", { tid: "#Eskritor#" + skRef.current, cid: pkRef.current })
    navigate(`/`);
    window.location.reload();

}

const  onShareFile = () => {
    const url = "https://editor.eskritor.com/" + skRef.current + "/" + pkRef.current;
    navigator.clipboard.writeText(url);
    showToast(t("Link copied to clipboard"));
    setIsDropdownOpen(false);
  
}



  return (
    <div className="dropdown multilayer-dropdown">
      <div
        className="btn dropdown-toggle no-toggle-icon p-1 round-color-icon theme-bg"
        
        role="button"
        id="dropdownMenuLink"
        onClick={(e) => {
          e.preventDefault();
          toggleDropdown();
        }}
        aria-expanded={isDropdownOpen}
      >
         <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
          >
            <path
              d="M7.10156 18.2109H22.156"
              stroke="#FFFCF9"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M7.10156 9.85742H22.156"
              stroke="#FFFCF9"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M7.10156 14H22.156"
              stroke="#FFFCF9"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
      </div>
      {isDropdownOpen && (
        <ul class="dropdown-menu show" aria-labelledby="dropdownMenuButton1">
        <li>
          <div class="dropdown-item" 
          onClick={() => {
            navigate(`/`);
            window.location.reload();
          }}
          >
            <span>
              <span class="drop-item-icon me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M6.89844 15V8.4C6.89844 7.26864 6.89844 6.70293 7.24992 6.35148C7.60137 6 8.16708 6 9.29844 6H15.2984H20.6984C21.8298 6 22.3955 6 22.747 6.35148C23.0984 6.70293 23.0984 7.26864 23.0984 8.4V15V21.75C23.0984 22.8814 23.0984 23.4471 22.747 23.7985C22.3955 24.15 21.8298 24.15 20.6984 24.15H15.2984H9.29844C8.16708 24.15 7.60137 24.15 7.24992 23.7985C6.89844 23.4471 6.89844 22.8814 6.89844 21.75V15Z"
                    stroke="#4E4C4A"
                    stroke-width="2"
                  />
                  <path
                    d="M11.3984 15H18.5984"
                    stroke="#4E4C4A"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M11.3984 11.4004H18.5984"
                    stroke="#4E4C4A"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M11.3984 18.5996H15.8984"
                    stroke="#4E4C4A"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </span>
              {t("New File")}
            </span>
          </div>
        </li>
        <li>
          <div class="dropdown-item" 
              onClick={() => {
                onSaveFile()
                setIsDropdownOpen(false)
              }}
>
            <span class="drop-item-icon me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M14.875 18.3752L6.125 18.375M14.875 18.3752L15.5777 18.375C16.5559 18.375 17.045 18.375 17.419 18.1844C17.7482 18.0167 18.0167 17.7488 18.1844 17.4195C18.375 17.0455 18.375 16.5559 18.375 15.5777V8.06711C18.375 7.67438 18.375 7.47706 18.3331 7.28967C18.2958 7.1225 18.2344 6.96195 18.1503 6.81271C18.057 6.64715 17.9261 6.50173 17.6688 6.21581L15.258 3.53711C14.9598 3.20577 14.8087 3.03783 14.6272 2.91723C14.464 2.80875 14.2837 2.72876 14.0939 2.68001C13.8797 2.625 13.6498 2.625 13.1906 2.625H5.42518C4.44508 2.625 3.95467 2.625 3.58033 2.81574C3.25104 2.98351 2.98351 3.25104 2.81574 3.58033C2.625 3.95467 2.625 4.44508 2.625 5.42517V15.5752C2.625 16.5553 2.625 17.0446 2.81574 17.419C2.98351 17.7482 3.25104 18.0167 3.58033 18.1844C3.9543 18.375 4.44412 18.375 5.4223 18.375H6.125M14.875 18.3752V15.0473C14.875 14.0691 14.875 13.5793 14.6844 13.2053C14.5167 12.8761 14.2482 12.6085 13.919 12.4407C13.5447 12.25 13.0553 12.25 12.0752 12.25H8.92518C7.94508 12.25 7.45467 12.25 7.08032 12.4407C6.75104 12.6085 6.48351 12.8761 6.31574 13.2053C6.125 13.5796 6.125 14.0701 6.125 15.0502V18.375M13.125 6.125H7.875"
                  stroke="#4E4C4A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            {t("Save File")}
          </div>
        </li>
        <li>
          <div class="dropdown-item" 
          onClick={() => {
            onDuplicateFile()
            setIsDropdownOpen(false)
          }}
          >
            <span class="drop-item-icon me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 19.6667V10.3333C18 9.04467 16.9553 8 15.6667 8H6.33333C5.04467 8 4 9.04467 4 10.3333V19.6667C4 20.9553 5.04467 22 6.33333 22H15.6667C16.9553 22 18 20.9553 18 19.6667Z"
                  stroke="#4E4C4A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.3333 17H20.6667C21.9553 17 23 15.955 23 14.666V5.33401C23 4.04497 21.9553 3 20.6667 3H20.6626L11.3293 3.01613C10.0422 3.01836 9 4.06267 9 5.35014V7.66398"
                  stroke="#4E4C4A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 12V19"
                  stroke="#4E4C4A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14 15H7"
                  stroke="#4E4C4A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            {t("Duplicate")}
          </div>
        </li>
        <li class="dropdown-submenu">
          <div class="submenu-link dropdown-item" onClick={toggleSubmenu} >
            <span>
              <span class="drop-item-icon me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                >
                  <g clip-path="url(#clip0_109_1224)">
                    <path
                      d="M0.96875 10.7981V11.622C0.96875 12.2777 1.24661 12.9066 1.7412 13.3703C2.23579 13.834 2.9066 14.0944 3.60606 14.0944H12.3958C12.7421 14.0944 13.0851 14.0305 13.4051 13.9062C13.725 13.782 14.0158 13.5999 14.2607 13.3703C14.5056 13.1407 14.6998 12.8681 14.8324 12.5681C14.9649 12.2682 15.0331 11.9467 15.0331 11.622V10.7981M11.518 7.50087L8.00093 10.7981M8.00093 10.7981L4.48484 7.50087M8.00093 10.7981V0.908203"
                      stroke="#4E4C4A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_109_1224">
                      <rect width="16" height="15" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
             {t("Download")}
            </span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 5 9"
                fill="none"
              >
                <path
                  d="M1 1L4 4.5L1 8"
                  stroke="#4E4C4A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </span>
          </div>
          {isSubmenuOpen && (
          <ul class="submenu dropdown-menu show">
            <li>
              <div class="dropdown-item" 
              onClick={() => {
                eskritorExportFile("pdf")
                setIsDropdownOpen(false)
              }}
              >
                <span class="drop-item-icon me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      d="M6.5 0C5.60625 0 4.875 0.73125 4.875 1.625V24.375C4.875 25.2687 5.60625 26 6.5 26H22.75C23.6437 26 24.375 25.2687 24.375 24.375V6.5L17.875 0H6.5Z"
                      fill="#E2E5E7"
                    />
                    <path
                      d="M19.5 6.5H24.375L17.875 0V4.875C17.875 5.76875 18.6062 6.5 19.5 6.5Z"
                      fill="#B0B7BD"
                    />
                    <path
                      d="M24.375 11.375L19.5 6.5H24.375V11.375Z"
                      fill="#CAD1D8"
                    />
                    <path
                      d="M21.125 21.125C21.125 21.5719 20.7594 21.9375 20.3125 21.9375H2.4375C1.99062 21.9375 1.625 21.5719 1.625 21.125V13C1.625 12.5531 1.99062 12.1875 2.4375 12.1875H20.3125C20.7594 12.1875 21.125 12.5531 21.125 13V21.125Z"
                      fill="#F15642"
                    />
                    <path
                      d="M5.16797 15.3938C5.16797 15.1793 5.33697 14.9453 5.60916 14.9453H7.10984C7.95484 14.9453 8.71534 15.5108 8.71534 16.5947C8.71534 17.6217 7.95484 18.1937 7.10984 18.1937H6.02516V19.0517C6.02516 19.3377 5.84316 19.4994 5.60916 19.4994C5.39466 19.4994 5.16797 19.3377 5.16797 19.0517V15.3938ZM6.02516 15.7635V17.382H7.10984C7.54534 17.382 7.88984 16.9977 7.88984 16.5947C7.88984 16.1405 7.54534 15.7635 7.10984 15.7635H6.02516Z"
                      fill="white"
                    />
                    <path
                      d="M9.98756 19.4995C9.77306 19.4995 9.53906 19.3825 9.53906 19.0973V15.4069C9.53906 15.1737 9.77306 15.0039 9.98756 15.0039H11.4752C14.4441 15.0039 14.3791 19.4995 11.5337 19.4995H9.98756ZM10.3971 15.7969V18.7073H11.4752C13.2294 18.7073 13.3074 15.7969 11.4752 15.7969H10.3971Z"
                      fill="white"
                    />
                    <path
                      d="M15.4319 15.8497V16.8824H17.0886C17.3226 16.8824 17.5566 17.1164 17.5566 17.3431C17.5566 17.5576 17.3226 17.7331 17.0886 17.7331H15.4319V19.0973C15.4319 19.3248 15.2702 19.4995 15.0427 19.4995C14.7567 19.4995 14.582 19.3248 14.582 19.0973V15.4069C14.582 15.1737 14.7575 15.0039 15.0427 15.0039H17.3234C17.6094 15.0039 17.7784 15.1737 17.7784 15.4069C17.7784 15.6149 17.6094 15.8489 17.3234 15.8489H15.4319V15.8497Z"
                      fill="white"
                    />
                    <path
                      d="M20.3125 21.9375H4.875V22.75H20.3125C20.7594 22.75 21.125 22.3844 21.125 21.9375V21.125C21.125 21.5719 20.7594 21.9375 20.3125 21.9375Z"
                      fill="#CAD1D8"
                    />
                  </svg>
                </span>
                {t("Download as PDF")}
              </div>
            </li>
            <li>
              <div class="dropdown-item" 
              onClick={() => {
                
                eskritorExportFile("docx")
                setIsDropdownOpen(false)}}>
                <span class="drop-item-icon me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      d="M6.5 0C5.60625 0 4.875 0.73125 4.875 1.625V24.375C4.875 25.2687 5.60625 26 6.5 26H22.75C23.6437 26 24.375 25.2687 24.375 24.375V6.5L17.875 0H6.5Z"
                      fill="#E2E5E7"
                    />
                    <path
                      d="M19.5 6.5H24.375L17.875 0V4.875C17.875 5.76875 18.6062 6.5 19.5 6.5Z"
                      fill="#B0B7BD"
                    />
                    <path
                      d="M24.375 11.375L19.5 6.5H24.375V11.375Z"
                      fill="#CAD1D8"
                    />
                    <path
                      d="M21.125 21.125C21.125 21.5719 20.7594 21.9375 20.3125 21.9375H2.4375C1.99062 21.9375 1.625 21.5719 1.625 21.125V13C1.625 12.5531 1.99062 12.1875 2.4375 12.1875H20.3125C20.7594 12.1875 21.125 12.5531 21.125 13V21.125Z"
                      fill="#50BEE8"
                    />
                    <path
                      d="M3.43277 19C3.22579 19 3 18.8952 3 18.6414V15.3586C3 15.1504 3.22579 15 3.43277 15H4.86829C7.73305 15 7.66954 19 4.92395 19H3.43277ZM3.82791 15.7049V18.2944H4.86829C6.56096 18.2944 6.63544 15.7049 4.86829 15.7049H3.82791Z"
                      fill="white"
                    />
                    <path
                      d="M9.55207 18.998C8.54543 19.042 7.5 18.3652 7.5 17.0179C7.5 15.6651 8.54475 15 9.55207 15C10.5043 15.0488 11.5 15.7146 11.5 17.0179C11.5 18.3219 10.5043 18.998 9.55207 18.998ZM9.49217 15.7153C8.88234 15.7153 8.21873 16.1499 8.21873 17.0186C8.21873 17.8824 8.88302 18.3226 9.49217 18.3226C10.1183 18.3226 10.7874 17.8824 10.7874 17.0186C10.7874 16.1493 10.1183 15.7153 9.49217 15.7153Z"
                      fill="white"
                    />
                    <path
                      d="M12 17.0021C12 15.9257 12.6753 15 13.9581 15C14.4437 15 14.8287 15.1451 15.2354 15.4967C15.3861 15.6355 15.4029 15.8811 15.2522 16.0373C15.1015 16.1706 14.8727 16.1545 14.7388 16.0206C14.5106 15.7806 14.2762 15.7143 13.9581 15.7143C13.0994 15.7143 12.6871 16.3226 12.6871 17.0028C12.6871 17.6948 13.0938 18.3303 13.9581 18.3303C14.2762 18.3303 14.572 18.2013 14.8287 17.9731C15.001 17.8392 15.241 17.9055 15.3415 18.0401C15.4308 18.1629 15.4747 18.3694 15.2801 18.564C14.8894 18.9275 14.4213 19 13.9574 19C12.6083 19 12 18.0785 12 17.0021Z"
                      fill="white"
                    />
                    <path
                      d="M16.8611 15.1578L17.9989 16.3973L19.1368 15.1463C19.4769 14.7561 20.1373 15.2553 19.7646 15.6283C19.3722 16.0358 18.9602 16.4776 18.5679 16.9309L19.9019 18.3884C20.2616 18.8188 19.5423 19.232 19.1891 18.8073L17.9859 17.4531L16.8022 18.836C16.4687 19.2549 15.7297 18.7844 16.1025 18.3942L17.4169 16.9309C17.0049 16.4776 16.606 16.0358 16.2071 15.6283C15.7951 15.198 16.5603 14.7561 16.8611 15.1578Z"
                      fill="white"
                    />
                    <path
                      d="M20.3125 21.9375H4.875V22.75H20.3125C20.7594 22.75 21.125 22.3844 21.125 21.9375V21.125C21.125 21.5719 20.7594 21.9375 20.3125 21.9375Z"
                      fill="#CAD1D8"
                    />
                  </svg>
                </span>
                {t("Download as DOCX")}
              </div>
            </li>
            <li>
              <div class="dropdown-item" onClick={() => 
                {
                  
                  eskritorExportFile("html")
                  setIsDropdownOpen(false)
                  }}>
                <span class="drop-item-icon me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
                      fill="#E2E5E7"
                    />
                    <path
                      d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z"
                      fill="#B0B7BD"
                    />
                    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
                    <path
                      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
                      fill="#84BD5A"
                    />
                    <path
                      d="M2.60938 17.6274V14.2216C2.60938 14.0056 2.76537 13.8496 3.03462 13.8496C3.24462 13.8496 3.40062 14.0056 3.40062 14.2216V15.5289H5.35512V14.2216C5.35512 14.0056 5.54712 13.8496 5.76312 13.8496C5.97312 13.8496 6.13512 14.0056 6.13512 14.2216V17.6274C6.13512 17.8374 5.97312 17.9994 5.76312 17.9994C5.54712 17.9994 5.35512 17.8374 5.35512 17.6274V16.3149H3.40062V17.6274C3.40062 17.8374 3.24462 17.9994 3.03462 17.9994C2.76537 17.9994 2.60938 17.8374 2.60938 17.6274Z"
                      fill="white"
                    />
                    <path
                      d="M8.097 14.5993H7.04775C6.52575 14.5993 6.52575 13.832 7.04775 13.832H9.8835C10.4175 13.832 10.4175 14.5993 9.8835 14.5993H8.88825V17.627C8.88825 18.1483 8.09625 18.161 8.09625 17.627V14.5993H8.097Z"
                      fill="white"
                    />
                    <path
                      d="M11.6584 15.3669V17.5854C11.6584 17.8502 11.4424 17.9994 11.2272 17.9994C11.0352 17.9994 10.8672 17.8502 10.8672 17.5854V14.2097C10.8672 13.8977 11.1319 13.7949 11.2272 13.7949C11.4004 13.7949 11.5024 13.8977 11.6044 14.0117L12.9244 15.7922L14.2909 13.9449C14.4897 13.6997 14.9749 13.7949 14.9749 14.2097V17.5854C14.9749 17.8502 14.8062 17.9994 14.6149 17.9994C14.3997 17.9994 14.2309 17.8502 14.2309 17.5854V15.3669L13.2357 16.6262C13.0204 16.8909 12.7504 16.8909 12.5524 16.6262L11.6584 15.3669Z"
                      fill="white"
                    />
                    <path
                      d="M15.8086 14.2098C15.8086 14.0118 15.9766 13.8438 16.1866 13.8438C16.3786 13.8438 16.5406 14.0125 16.5406 14.2098V17.2142H18.1718C18.7591 17.2142 18.7718 17.9995 18.1718 17.9995H16.1873C15.9773 17.9995 15.8093 17.8502 15.8093 17.6335V14.2098H15.8086Z"
                      fill="white"
                    />
                    <path
                      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
                      fill="#CAD1D8"
                    />
                  </svg>
                </span>
                {t("Download as HTML")}
              </div>
            </li>
          </ul> )}
        </li>
        <li>
          <div class="dropdown-item" onClick={onShareFile}>
            <span class="drop-item-icon me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M5 12.5L5.00002 7.5V7C5.00002 6.0572 5.00003 5.58578 5.2929 5.2929C5.5858 5 6.05722 5 7.00007 5C7.72377 5 8.4321 5 8.7123 5C9.00233 5 9.14733 5 9.28668 5.01302C9.8875 5.06922 10.4574 5.30527 10.9219 5.69037C11.0297 5.7797 11.1322 5.88225 11.3373 6.0873L11.75 6.5C12.3618 7.11182 12.6677 7.41775 13.0341 7.62157C13.2353 7.73352 13.4488 7.82195 13.6703 7.88507C14.0734 8 14.506 8 15.3713 8H15.6516C17.6258 8 18.613 8 19.2546 8.5771C19.3136 8.63017 19.3698 8.68635 19.4229 8.74537C20 9.38702 20 10.3741 20 12.3484V14C20 15.3897 20 16.8623 20 18.0008C20 18.9431 20 19.4142 19.7071 19.7071C19.4142 20 18.9431 20 18.0008 20C16.8623 20 15.3897 20 14 20H11C9.61027 20 8.1377 20 6.9992 20C6.05692 20 5.58578 20 5.2929 19.7071C5 19.4142 5 18.9428 5 18V17"
                  stroke="#4E4C4A"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M5 14.75C9.83275 14.75 8.41725 14.75 13.25 14.75M13.25 14.75L10.1563 12.5M13.25 14.75L10.1563 17"
                  stroke="#4E4C4A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            {t("Share")}
          </div>
        </li>
        <li>
          <div class="dropdown-item text-danger" 
        onClick={handleDelete}
          >
            <span class="drop-item-icon me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
              >
                <path
                  d="M12.0391 7.54078C12.3974 6.52706 13.3641 5.80078 14.5005 5.80078C15.6369 5.80078 16.6037 6.52706 16.962 7.54078"
                  stroke="#EF0C0C"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M21.8956 9.2793H7.10547"
                  stroke="#EF0C0C"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M20.4447 11.4551L20.0445 17.4573C19.8906 19.7671 19.8136 20.922 19.061 21.626C18.3085 22.3301 17.151 22.3301 14.8361 22.3301H14.1633C11.8484 22.3301 10.6909 22.3301 9.93839 21.626C9.18581 20.922 9.10882 19.7671 8.95486 17.4573L8.55469 11.4551"
                  stroke="#EF0C0C"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M12.3242 13.6309L12.7592 17.9809"
                  stroke="#EF0C0C"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M16.6733 13.6309L16.2383 17.9809"
                  stroke="#EF0C0C"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </span>
            {t("Delete")}
          </div>
        </li>
      </ul>
      )}
    </div>
  );
};

export default EditorDropdown;
